import CancleDebtData from "./CancleDebtData";

export default class EducationalJoiningApplicationCancel {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.educationalJoiningApplicationToken = "";
    this.cancellationReasonAr = "";
    this.cancellationReasonEn = "";
    this.cancellationReasonUnd = "";
    this.cancellationNote = "";
    this.cancleDebtsData = [new CancleDebtData()];
  }
  fillData(data) {
    if (data) {
      this.educationalJoiningApplicationToken =
        data.educationalJoiningApplicationToken ?? "";
      this.cancellationReasonAr = data.cancellationReasonAr ?? "";
      this.cancellationReasonEn = data.cancellationReasonEn ?? "";
      this.cancellationReasonUnd = data.cancellationReasonUnd ?? "";
      this.cancellationNote = data.cancellationNote ?? "";
      this.cancleDebtsData = data.cancleDebtsData ?? [new CancleDebtData()];
    } else {
      this.setInitialValue();
    }
  }
}
