var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"}),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("choseGroup"))+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-evenly","align-items":"center","flex-wrap":"wrap"}},_vm._l((_vm.allEduGroups),function(group,index){return _c('div',{key:(group.itemToken, index)},[_c('div',{staticClass:"check-box-chose-group-joining-container"},[_c('label',{attrs:{"for":'choseGroup-joining-id-' + group.itemToken}},[_vm._v(_vm._s(group.itemName))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedGroups),expression:"selectedGroups"}],staticStyle:{"margin":"0 12px"},attrs:{"id":'choseGroup-joining-id-' + group.itemToken,"type":"checkbox"},domProps:{"value":group.itemToken,"checked":Array.isArray(_vm.selectedGroups)?_vm._i(_vm.selectedGroups,group.itemToken)>-1:(_vm.selectedGroups)},on:{"change":function($event){var $$a=_vm.selectedGroups,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=group.itemToken,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedGroups=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedGroups=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedGroups=$$c}}}})])])}),0)]),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("students.data")))]),_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":"userStudentToken","value":_vm.educationalJoiningApplication.userStudentToken,"options":_vm.userTokenOptions,"title":_vm.$t('students.select'),"imgName":'students.svg'},on:{"changeValue":function($event){_vm.educationalJoiningApplication.userStudentToken = $event;
            _vm.educationalJoiningApplication.addedByStudentTokenStatus =
              _vm.educationalJoiningApplication.userStudentToken ? true : false;}}}),_c('CustomInput',{attrs:{"isDisabled":_vm.educationalJoiningApplication.addedByStudentTokenStatus,"className":'col-md-6',"id":(_vm.id + "-studentNameAr"),"value":_vm.educationalJoiningApplication.studentNameAr,"title":_vm.$t('Users.nameAr'),"imgName":'students.svg'},on:{"changeValue":function($event){_vm.educationalJoiningApplication.studentNameAr = $event}}}),_c('CustomPhonePicker',{attrs:{"isDisabled":_vm.educationalJoiningApplication.addedByStudentTokenStatus,"className":'col-md-6',"id":(_vm.id + "-studentPhone"),"countryCodeName":_vm.educationalJoiningApplication.studentPhoneCountryCodeName,"countryCode":_vm.educationalJoiningApplication.studentPhoneCountryCode,"phoneNumber":_vm.educationalJoiningApplication.studentPhone,"title":_vm.$t('Users.phone'),"imgName":'phone.svg'},on:{"changeValue":function($event){_vm.educationalJoiningApplication.studentPhoneCountryCodeName =
              $event.countryCodeName;
            _vm.educationalJoiningApplication.studentPhoneCountryCode =
              $event.countryCode;
            _vm.educationalJoiningApplication.studentPhone = $event.phoneNumber;}}})],1)]),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priceListToken"),"value":_vm.educationalJoiningApplication.priceListToken,"options":_vm.priceListTokenOptions,"title":_vm.$t('PriceLists.select'),"imgName":'PriceLists.svg'},on:{"changeValue":function($event){_vm.educationalJoiningApplication.priceListToken = $event}}}),(
            _vm.educationalJoiningApplication.subscriptionTypeToken ==
            _vm.SUBSCRIPTION_TYPE_TOKENS.Session
          )?[_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-subscriptionToDateTime"),"type":"dateTime","value":_vm.educationalJoiningApplication.subscriptionToDateTime,"title":_vm.$t('EducationalJoiningApplications.subscriptionToDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.educationalJoiningApplication.subscriptionToDateTime =
                $event.dateTime}}})]:_vm._e(),(
            _vm.educationalJoiningApplication.subscriptionTypeToken ==
            _vm.SUBSCRIPTION_TYPE_TOKENS.Duration
          )?[_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-subscriptionToDateTime"),"type":"dateTime","value":_vm.educationalJoiningApplication.subscriptionToDateTime,"title":_vm.$t('EducationalJoiningApplications.subscriptionToDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.educationalJoiningApplication.subscriptionToDateTime =
                $event.dateTime}}})]:_vm._e(),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-educationalJoiningApplicationNotes"),"value":_vm.educationalJoiningApplication.educationalJoiningApplicationNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.educationalJoiningApplication.educationalJoiningApplicationNotes =
              $event}}})],2)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }