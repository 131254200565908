import { STATUS } from "./../../../../utils/constants";
import apiEducationalJoiningApplication from "./../../../../api/educational/educationalJoiningApplications";
import EducationalJoiningApplicationWithDebts from "./../../../../models/educational/educationalJoiningApplications/EducationalJoiningApplicationWithDebts";

export default {
  mixins: [],
  data() {
    return {
      isAllDataLoaded: false,
      educationalJoiningApplicationWithDebts:
        new EducationalJoiningApplicationWithDebts(),
    };
  },
  props: ["educationalJoiningApplicationToken"],
  methods: {
    async getDetailsWithDebts() {
      this.isLoading = true;
      try {
        this.educationalJoiningApplicationWithDebts.setInitialValue();

        const response =
          await apiEducationalJoiningApplication.getDetailsWithDebts({
            token: this.educationalJoiningApplicationToken,
          });
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.educationalJoiningApplicationWithDebts.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
};
